@import './mobile.css';

@import "~react-datetime/css/react-datetime.css";

.App {
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.main{
  padding: 20px;
  font-size: 1.05rem;
}


body{
  font-family: 'FS Lucas Cy Web Regular';
}

.content{
  font-family: 'Tiempos Text';
}

h2{
    font-family: 'FS Lucas Cy Web SemiBold';
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 2em;
    font-weight: bold;
}

h3{
    margin-top: 10px;
    font-size: 1.4em;
    font-weight: bold;
}

a{
  color: #034a0f
}

.step-action{
    margin-top: 20px;
    padding: 5px;
}

.btn{
    margin-right: 5px;
}


.react-tabs__tab{
    border-width: 0px 0px 1px 0px;
}

.react-tabs__tab--selected{
    border-bottom: 3px solid #88b04b;
}

.field-container{
    margin-top: 20px;
}

.field-container > .container-title{
    font-size: 1.3em;
    font-weight: 400;
}

.field-mode-edit{
   margin-top: 10px
}

.field-mode-view{
    margin-top: 5px;
}

.field-description{
    font-style: italic;
    color:#333333;
    font-size: 90%;
}

.result-required label, .field-validation-required label{
    color:red;
}

.field-mode-edit.required .field-label::after{
  content: '*';
  color: red;
}

.react-tabs__tab-panel{
}


.form-side{
    width: 29%;
    margin-left: 10px;
    padding: 10px;
    background: #f9f9f9;
    vertical-align: top;
    top:0px;
    height: 100%;
    position: absolute;

}

.form-step{
    position: relative;
}

.react-tabs__tab-list{
    margin-bottom: 0px;
}

.field label{
  margin-right: 10px;
}

.fieldtype-checkbox label{
    width: 100%
}


.fieldtype-checkbox label div.field-label{
    margin-left: 5px;
    display: inline-block;
    width: calc(100% - 20px);
    vertical-align: bottom;
    font-weight: normal;
}

.fieldtype-checkbox label div.field-value{
  float: left;
}

.field-mode-view.fieldtype-checkbox label{
    width: 90%;
    margin-left: 5px;
}

.field-mode-view.fieldtype-checkbox .field-value{
  float: left;
}

.field-container.level2{
    border-top: 1px solid #cccccc;
    padding: 10px;
    margin: 20px 5px 0px 10px;
}

.preview .field-container.level2{
    display: block;
    width: 100%;
    border-top: none;
}

.fieldtype-checkbox{
        margin-top: 10px;
        font-size: 0.9em;
}

.field-container.level2 > .children > .fieldtype-output_h{
  font-weight: 600;
  margin-top: 20px;
}

.field-container.level2 > .children > .field-mode-edit label{
    margin-bottom: 0px;
}

.field-container.level2 > .container-title{
    display: block;
    margin-top: -24px;
    margin-left: -14px;
    font-size: 1.1em;
}

.field-container.level2 > .container-title span{
    background: #fcfbf9;
    padding: 0px 10px;
    display: inline-block;
    border-radius: 5px;
}

.field-container > .container-title .icon-info::before{
  color: white;
}

.field-mode-edit input[type=checkbox], .field-mode-edit input[type=radio]{
    vertical-align: top;
    margin-top: 0.25rem;
}

.field-container.level1{
    background: #FCFBF9;
    border-radius: 4px 4px  0px 0px;
    border: 1px solid #88B04B;
    padding-bottom: 20px;
}

.field-container.level1 > .container-title{
    background: #88B04B;
    color: white;
    font-size: 1em;
    padding: 5px 10px;
    font-weight: 500;
}

.field-container.level1 > .children{
    margin-left: 10px;
    margin-right: 10px;
}

.field{
    margin-top: 10px;
}

.fieldtype-text > input[type='text']{
    width: 50%;
    display: block;
}

.fieldtype-text textarea{
    width: 70%;
}

.field-container.level2{
    display: inline-block;
    width: 23%;
    vertical-align: top;
}

.report-type-report_public_sector .field-container.level2:not(:first-child){
  width: 15%;
}

.report-type-report_public_sector .field-container.level2:first-child{
  width: 50%;
}

.field > textarea{
    height: 120px;
}

.fieldtype-text-multiline{
  min-height: 90px;
}

.icon-info::before{
    margin-left: 0.2rem;
    font-family: "Font Awesome 5 Free";
    content: '\f05a';
    cursor: pointer;
    font-weight: 900;
    font-style: normal;
    font-size: 16px;
    color: green;
}

.icon-csrd::before{
  content: 'CSRD';
  cursor: pointer;
  font-style: normal;
  font-size: 12px;
  color: white;
  font-family: Arial;
}

.icon-csrd{
  height: 18px;
  display: inline-block;
  background: #EA8651;
  line-height: 1.2;
  text-align: center;
  padding: 0px 3px;
  border-radius: 4px;
  font-weight: bold;
  margin-left: 5px;
  margin-right: 3px;
}

.icon-csrd:hover{
  opacity: 0.8;
}

.form-messgae{
  position: fixed;
  right: 15px;
  bottom: 100px;
  z-index: 10;
  font-style: italic;
  color: red;
  background: white;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #cccccc;
}

.right{
  float: right;
}

.form-field .tip{
    max-width: 400px;
}

.field .tip .multi-line{
    text-align: left;
}

.field-container a{
  color: green
}

a.closable{
  float: right;
  color: white;
}

.level1 > .container-title{
  cursor: pointer;
}

.level1 > .container-title:hover, .level1 > .container-title:hover a{
   color: #f0f0f0
}

.level2 a.closable{
  display: none
}

a.closable:hover{
  color: #f0f0f0;
}


.field.file .field-input{
  margin-left: 5px;
    background: #f7f7f7;
    border: 1px solid #efefef;
    width: 100%;
    padding: 10px;
    width: 50%;
    border-radius: 3px;
}

.field input.document-name{
  width: 200px;
}

.file-upload .success::before{
   content: '\f058';
   font-family: "Font Awesome 5 Free";
   color: green;
}

.block{
  margin-top:10px;
}

.indicator{
}

.indicator select{
  width: 60%;
}

.indicator h5{
  font-size: 1.15em;
  margin-top: 20px;
}

.indicator table td{
  padding: 5px 10px;
}

.indicator .added{
  width: 400px;
  overflow: hidden;
  white-space: nowrap;
}

.indicator table  input{
  width: 80px;
}

.right-float{
  float: right;
}


.error{
  color: red;
  padding: 5px;
}

/* .indicator .indicator-body{
  width: 70%;
  margin-top: 10px;
  border: 1px solid #cccccc;
  background: #f8ffed;
  padding: 10px;
  border-radius: 5px;
} */

input[type='number'] {
    -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.fieldtype-checkbox{
  position: relative;
}

.fieldtype-checkbox .field-comment{
    position: absolute;
    right: 0px;
    float: none;
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.fieldtype-checkbox .field-comment:hover{
    width: auto;
    text-overflow:inherit;
    white-space:normal;
}

.field-comment{
  float: right;
  border: 1px solid #f3d11f;
  background: #fff5c1;
  border-radius: 5px;
  padding: 5px;
}

.loading{
  height: 200px;
  background-size: 40px;
  background-repeat: no-repeat;
  background-position: center;
}

span.loading{
    display: inline-block;
    width: 20px;
    height: 1em;
    background-size: 100%;
}

.report-comment{
  position: fixed;
    right: 10px;
    background: #fff5c1;
    border: 1px solid #f3d21f;
    padding: 10px;
    border-radius: 5px;
    z-index: 10;
}

.activity-body{
  padding: 10px;
}


.report-comment .fa-comment, .field-comment .fa-comment{
  color: #ff0000
}


.myreport{
  border: 1px solid #cccccc;
  padding: 10px;
  display: inline-block;
  border-radius: 4px;
}

.myreport table{
  margin-bottom: 10px;
}

.myreport table td{
  padding: 5px;
}

.btn-primary{
  background-color: #357940;
  border-color: #2e6337;
}

.btn-primary:hover{
    background-color: #386940;
    border-color: #1f5d2a;
}

.fields-parent{
  margin-top: 20px;
}

.fields-parent .field-title{
  font-size: 1.3em;
}

.fields-parent .field-description{
  padding: 10px;
  font-style: normal;
  font-size: 100%;
}


.field .btn-sm{
  padding: 2px 8px;
}

/* .field.eth_tablegroup .groupitem{
  margin-top: 10px;
  margin-bottom: 40px;
  width: 70%;
  padding: 10px;
  padding-bottom: 20px;
  border: 1px solid #88b04b;
  border-radius: 4px;
} */

#current_user{
  margin-left: 2px;
}


.login .main-anonymous{
  margin-top:0px;
}

.main-anonymous{
  max-width: 800px;
  margin: 80px auto 5% auto;
}

.login .logo{
  margin-left: 30px;
}

.login .logo img{
  height: 50px;
}

.login .buttons{
  margin-top: 10px;
}

.login h2{
  margin-top: 10px;
  margin-bottom: 20px;
}

.login .col{
  padding-left: 40px;
  padding-right: 40px;
}

.login-line{
  border-left: 1px solid rgb(199, 200, 202);
}

.login-body{
  padding: 20px;
  border-radius: 5px;
}

.login-body > form > div{
  margin-top: 10px;
}


input[type="radio"] + span{
  margin-left: 5px;
  margin-right: 5px;
}

.current-language{
  font-weight: bold;
}

.saved-time{
  color: #333333;
  font-style: italic;
  font-size: 90%;
}


.document-list-display{
  position: fixed;
  right: 0px;
  top: 30%;
  right: 20px;
  padding: 10px;
  background:#ffffff;
  border:1px solid #efefef;
  border-radius: 5px;
  z-index: 10;
  max-height: 70%;
  overflow-y: auto;
}

.document-list-display h3{
  font-size: 1.2em;
}

.user-profile .fieldtype-text > .field-value{
  display: inline-block;
}


.user-profile .field > label{
  width: 150px;
}


.field.password > input{
  width: 200px;
  display: inline-block;
  margin-right: 10px;
}

.fieldtype-image {
  margin-top: 10px;
}

.fieldtype-image .field-value{
  display: inline-block;
}

.field .tip a{
  color: #ffcc00;
}

.fieldtype-image img{
  max-width: 200px;
}


.field-preferance > div{
  margin-left: 20px;
}

.field-preferance .field-value{
  display: inline-block;
  width: 200px;
}


.eth_documentlist th{
  font-weight: normal;
}

.output_h.level-requirement{
  background: #fff2d5;
  border: 1px solid #fbe0a5;
  max-width: 70%;
  display: inline-block;
  padding: 10px;
  padding-left: 30px;
  margin: 20px 0px;
  font-size: 95%;
  position: relative;
  line-height: 2;
}

.output_h.level-requirement::before{
    content: '\f129';
    font-family: "Font Awesome 5 Free";
    font-weight: bold;
    position: absolute;
    left: 15px;
    top: 8px;
    color: #2f711a;
    font-size: 16px;
}

.output_h.level-requirement a{
    background: green;
    color: white;
    border: 1px solid #008001;
    padding: 2px 10px;
    border-radius: 4px;
    text-decoration: none;
    margin-left: 10px;
    display: inline-block;
}

.output_h.level-requirement a:hover{
  background: #0d8c0d
}

.field.preview > div{
  margin-top: 20px;
  width: 80%;
  height: 700px;
  overflow: scroll;
  border: 1px solid #cccccc;
  padding: 20px;
  box-shadow: inset 0px 0px 3px #adadad;
  border-radius: 4px;
}

.preview .fieldtype-checkbox{
  margin-top: 0px;
}

.preview .fieldtype-text .field-value, .preview .fieldtype-int .field-value{
  margin-top: 5px;
  background: #f3f3f3;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  max-width: 70%;
  min-height: 40px;
  padding: 10px;
}

.preview .fieldtype-text .fieldtype-text-multiline{
  white-space: pre-wrap;
  min-height: 80px;
}

.preview .level-requirement{
  display: none;
}


.form-step.step9 .document-list-display{
  display: none;
}

.preview .level1.field-step9{
  display: none;
}


.table th{
  font-weight: 500;
}

input[type='text'].form-control, textarea.form-control{
  /* font-size: 0.9rem; */
}

.eth_documentlist .file-upload input{
  display: block;
}

.user-profile .field-login input, .user-profile .field-company_name input, .user-profile .field-company_category input, .user-profile .company_branch input{
pointer-events: none;
background: #f0f0f0
}

.profile h1{
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 2em;
}


.savedraft-result{
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 10;
}

.savedraft-result .saved-time{
  padding: 5px;
}

.fieldtype-eth_tablelist td > textarea{
  width: 100%;
}

.eth_tablegroup .groupitem .title{
  font-weight: bold;
}

.container-close + .children{
  display: none;
}

.container-close i.fas::before{
  content: "\f077"
}

.document-list-display .document-title{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  display: inline-block;
}

textarea.field-value{
  height: 150px;
  display: block;
}

.user-profile .field-label{
   width: 150px;
   padding: 5px;
}

.field-label{
  white-space: pre-line;
}

.field-mode-edit > .field-label, .field-mode-view > .field-label{
  width: auto;
  font-weight: normal;
}

.field-mode-view > .field-value{
  display: block;
}

.user-profile .field-mode-edit .field-value{
  display: inline-block;
  width: 300px;
}

.myreport-status .messagelist{
  padding: 20px;
  margin-right: 20px;
  max-width: 600px;
  border: 1px solid #eeeeee;
  border-radius: 4px;
}

.messagelist h2{
  margin-top: 0px;
}

.react-tabs__tab-panel .messagelist h2{
  display: none
}

.message-item{
  padding: 10px;
  white-space: pre-wrap;
}

.message-subject{
  font-weight: bolder;
}

.message-sender{
  color: #417935;
  font-size: 90%;
}

.message-body{
  padding: 10px;
  font-size: 95%;
}

.message-item:not(:last-child){
    border-bottom: 1px solid #f0f0f0;
}

.message-item > span{
  font-size: 90%;
  font-style:italic;
}

.language, .language a{
  color: white;
}

.login-language{
   float: right;
   margin-right: 15px;
}

.evaluation-only .level1.field-step1, .evaluation-only .level1.field-step2, .evaluation-only .field-report_1e, .evaluation-only .field-step_3b{
  display: none !important;
}

.evaluation-only .field-container.level2 > .children > *{
  display: none;
}

.evaluation-only .field-container.level2 > .children > .field-container.level3{
  display: block;
}

.box{
  border: 1px solid #cccccc;
  background: #f6f6f6;
  padding: 10px;
  border-radius: 4px;
}


.indicator-add{
    border: 1px solid #d0deb8;
    padding: 10px;
    background: rgb(217 242 181 / 54%);
    width: 70%;
}

.link-high{
  /* border:1px solid #cccccc; */
  padding: 4px;
  padding: 10px 20px;
  display: block;
  margin: 5px;
  border-radius: 5px;
  background-color:rgb(238, 238, 238);
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
}

.link-high i{
  margin-left: 5px;
}

.link-high:hover{
  text-decoration: none;
}


.main{
  background-color: rgba(40, 46, 54, 0.05);
  min-height: 70vh;
}

.main >div{
  /* max-width: 1280px; */
  /* margin: auto; */
}

.main-area{
  width: 75%;
  background-color: #ffffff;
  padding: 10px 10px 50px 10px;
  border-radius: 4px;
}

.side{
  width: 25%;
  margin-left: 10px;
  border-radius: 4px;
}

.db-block{
  margin-bottom: 10px;
  background-color: #ffffff;
  padding: 10px;
}

.db-block h2{
  margin-top: 0px;
  font-size: 16px;
  color: rgb(55, 89, 66)
}

.main-layout{
  display: flex;
}

.content-container{
  padding: 10px;
}

.max-700 {
  max-width: 710px;
}

.content-wrapper{
  max-width: 960px;
  margin: auto;
  font-size: 1rem;
}

.content h1{
  margin-top: 30px;
  margin-bottom: 20px;
}

.content h2{
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: Tiempos Text;
  font-weight: bold;
}

.profile-picture img{
  width: 80px;
  height: 80px;
  border-radius: 40px;
}

.profile-picture{
  text-align: center;
  padding: 5px;
}


a{
  text-decoration: none !important;
}

.content img{
  max-width: 100%;
}

.user-profile .field-id-disabled{
  display: none;
}

@media only screen and (max-width: 600px) {
  .login .row{
    display: block;
  }

  .login .col{
    width: 100%;
    max-width: 100%;
  }

  .login .login-line{
    border-left: none;
  }
}

.coverimage-upload img{
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #666666;
}

.coverimage-upload img:hover{
  filter: contrast(110%);
}

.image-crop{
  text-align: center;
  width: 800px;
}

.image-crop .ReactCrop__image{
  max-height: 600px;
  max-width: 600px;
}

.modal-800{
  width: 900px;
  max-width: none !important;
}

.tab-right > a:first-child{
  margin-left: auto;
}

.category-required{
  color: red
}

.field-validation-failed input{
  border-color:#cccccc !important;
}

.field-mode-edit > .field-label, .eth_tablelist > label{
  max-width: 70%;
}

.report-type-report_public_sector .field-innledning_0{
  padding: 15px
}

.report-type-report_public_sector .field.preview .field-container.level2{
  width: 100%
}

.report-type-report_public_sector .field-innledning_0{
  max-width: 70%;;
}

.sending .btn-send{
  cursor: not-allowed;
  pointer-events: none;
  background-color: #cccccc;
  border-color: #aeaeae;
  color: #7d7d7d;
}

.myreport-feedback .level-evaluation h3{
  font-size: 16px;
}

.myreport-feedback .level-evaluation .level-header{
  display: none !important;
}

.rsw-ce{
  background-color: white;
  min-height: 100px;
}

.rsw-btn{
  font-size: 1.1em;
}

.rsw-toolbar{
  padding: 0px 5px;
}

.tip a {
  color: #fff !important;
  text-decoration: underline !important;
  text-decoration-style: dotted !important;
}


.rsw-editor .rsw-ce span, .rsw-editor .rsw-ce div{
  font-size: inherit !important;
}

.richtext-readonly > div span, .richtext-readonly > div div{
  font-size: inherit !important;
}

.header-message{
  position: absolute;
  left: 40%;
  background: #fdd;
  color: black;
  padding: 5px 10px;
  border-radius: 5px;
}

.rsw-ce:focus{
  outline: none !important;
}