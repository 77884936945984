.nav-tabs .nav-link{
    border:none;
    font-weight: bold;
    padding: 0.5rem 0.7rem;
}

.nav-tabs .nav-link.active{
    border-bottom: 3px solid green;
}


ul.plain li{
    display: inline-block;
    padding: 5px;
}

ul.plain a:hover{
    text-decoration: none;
}

.selected a{
    background-color: #00440c;
    color: white !important;
}

.nav-buttons li a{
    font-size: 1.2rem;
    border: 1px solid #00440c;
    padding: 10px 20px;
}