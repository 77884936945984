.resource-icon{
    font-size: 18px;
}

.resource-line{

}

.resource-line hr{
    margin: 8px 5px;
    border-top-color: #dddddd;
}

.resource-line > a{
    border-left: 3px solid #375942;
    display: block;
    color: black;
    padding: 0px 10px
}

.resource-from{
    color: rgba(40,46,54,.75);
    font-size: 0.875rem;
}

.resource-title{
    font-size: 1.1rem;
}