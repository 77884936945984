@media only screen and (max-width: 600px) {
    .main-layout{
        display: block !important;
    }

    .main-area{
        width: 100% !important;
    }

    .side{
        width: 100% !important;
        margin-left: 0px !important;
        margin-top: 10px;
    }

    .right{
        float: none !important;
    }

    .usermenu{
        float: left;
    }

    .menu nav{
        padding: .5rem 1rem;
    }

    .main{
        padding: 10px 0px !important;
    }

    .menu .navbar-nav a{
        padding: 5px 0px;
    }

    h1{
        font-size: 18px;
    }
}
