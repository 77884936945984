.profile .field-mode-edit > .field-label,.profile .field-mode-view > .field-label{
    width: 150px;
}

.profile .field-mode-view > .field-value{
    display: inline-block;
}

.member-logo{
    max-width: 200px;
    margin: 10px 10px 10px 0px;
    border-radius: 5px;
}

.fileupload-path, .fileupload-delete{
    display: none;
}