.dmb-container{
    display: flex;
}

.dmb-block{
    width: 30%;
    font-size: 15px;
}

.dmb-container.dmb-column2{
    /* display: flex; */
}

.dmb-container.dmb-columns-3 > div{
    width: 33.3%;
}

.dmb-container.dmb-columns-4 > div{
    width: 25%;
}

.dmb-container.dmb-columns-2 > div{
    display: inline-block;
    width: 50%;
}

.line-header{
    font-weight: bolder;
    border-left: 4px solid rgb(55, 89, 66);
    padding: 2px 15px;
    cursor: pointer;
}

.dmb-expandable .line-body{    
    padding: 15px;
    max-width: 710px;
}


.dmb-expandable .icon-open{
    transition-duration: 0.2s;
}

.dmb-expandable.open .icon-open{
    transform: rotate(45deg);   
}

.dmb-expandable{
    padding: 0.5rem 0px;
    border-bottom: 1px solid rgb(216, 216, 216);
}


.line-container{
    padding: 10px 0px;
  }

.dmb-block.type-article{
    padding: 10px;
    background-color: #eeeeee;
}

.width-full{
    width: 100%;
}

.text-size-2{
    font-size: 1.1rem;
}


.theme-smallspace{
    /* width: 83%; */
    margin: auto;
    margin-top: 2rem;
    background-color: rgb(245, 243, 240);
    padding:0px 8% 2rem 8%; 
}

.position-bottom{
    position: absolute;
    bottom: 0px;
}

.color-white{
    color:white;
}

.btn-round{
    display: inline-block;
    color: white !important;
    border: 1px solid #ffffff;
    padding: 5px 15px;
    border-radius: 20px;
    font-weight: bold;
}

.space-small{
    padding: 5%
}

.text-shadow-small{
    text-shadow: 1px 1px 1px #6e6e6e;
}

.text-5{
    font-size: 2rem;
}

.block-container > h2.block-name{
    font-size: 2.5rem;;
}

h2.block-name h2.block-name{
    font-size: 1.3rem;;
}

.block-container{
    padding-top: 40px;
}

.article-block a, .article-block a:hover{
    color: black;
    text-decoration: none;
}

.article-block h2{
    font-weight: normal;
}

.article-block a:hover h2{
    text-decoration: underline;
}