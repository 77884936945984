
.activity{
    margin-top: 5px;
    padding: 5px;
    background: #fcfcfc;
    border-radius: 4px;
    border: 1px solid #f0f0f0
}

.activity-title{
    font-size: 80%;
    font-weight: bold;
}

.workflow-status{
  font-weight: normal;
  border-radius: 4px;
  padding: 3px 5px;
  margin: auto 2px;
  border: 1px solid #666666;
  color: white;
}

.status-0{
  background: #9c710e;
}

.status-0::before{
  content:"Returned"
}


.status-2, .signing-status-2,  .signing-status-0{
  background: #666666;
}

.status-2::before{
  content:"Sent in"
}

.status-3, .status-4{
  background: #656565;
}

.status-3::before{
  content:"Signing"
}

.status-4::before{
  content:"Signed"
}

.status-1, .signing-status-1{
    background: green;
}

.status-1::before{
  content:"Final"
}

.status-0.workflow-lang-nor-NO::before{
  content:'Returnerte';
}

.status-2.workflow-lang-nor-NO::before{
  content:'Sendt inn';
}

.status-3.workflow-lang-nor-NO::before{
  content:'Signering';
}

.status-4.workflow-lang-nor-NO::before{
  content:'Signert';
}

.approve-field-comment{
  position: absolute;
  right: 0px;
  width: 28%;
}

.approve-field-comment textarea{
  width: 100%;
}

.approve-comment{
  position: fixed;
  top: 0px;
  right: 0px;
  box-shadow: -1px 1px 4px #bfbfbf;
  background: white;
  padding: 10px;
  width: 30%;
  border-bottom-left-radius: 4px;
}

.approve-field{
  float: right;
}

.approve-field-container{
  width: 70%;
}


.modal-header{
  border-bottom: none;
}
